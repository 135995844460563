import React from 'react';
import { Tabs } from 'antd';
import StaticsProduct from '../components/Statics/staticsProduct';
import TopMember from '../components/Statics/topMember';
const { TabPane } = Tabs;
const Statistics = () => {
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Thống kê sản phẩm" key="1">
                <StaticsProduct />
            </TabPane>
            <TabPane tab="Top 10 thành viên" key="2">
                <TopMember />
            </TabPane>
        </Tabs>
    );
};

export default Statistics;

// import React, { useState, useEffect } from 'react';
// import { Table, DatePicker, Row, Col, Tabs, Checkbox, message, Tooltip,Skeleton  } from 'antd';
// import axios from 'axios';
// import dayjs from 'dayjs';
// import { getProductSales } from '../api';
// import isBetween from 'dayjs/plugin/isBetween';

// dayjs.extend(isBetween);

// const { RangePicker } = DatePicker;
// const { TabPane } = Tabs;

// const Statistics = () => {
//     const [productData, setProductData] = useState([]); 
//     const [filteredProductData, setFilteredProductData] = useState([]); 
//     const [selectedDateRange, setSelectedDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
//     const [selectedLevels, setSelectedLevels] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const [memberData, setMemberData] = useState([]); 
//     const [filteredMemberData, setFilteredMemberData] = useState([]); 
//     const [selectedMemberDateRange, setSelectedMemberDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
//     const [selectedMemberLevel, setSelectedMemberLevel] = useState([]);

//     useEffect(() => {
//         const fetchProductData = async () => {
//           setLoading(true);
//             try {
//                 const user_id = localStorage.getItem('user_id');
//                 const start_date = selectedDateRange[0].format('YYYY-MM-DD');
//                 const end_date = selectedDateRange[1].format('YYYY-MM-DD');

//                 const response = await getProductSales({
//                     user_id: user_id,
//                     start_date: start_date,
//                     end_date: end_date,
//                 });

//                 if (response.data.success) {
//                     const processedData = response.data.data.map((item) => {
//                         const truncatedProductName = item.productName.length > 120 
//                             ? item.productName.substring(0, 100) + '...' 
//                             : item.productName;

//                         const thumbnailUrl = item.thumbnail || 'https://placehold.co/100x100/png';

//                         return {
//                             ...item,
//                             productName: truncatedProductName,
//                             thumbnail: thumbnailUrl,
//                         };
//                     });

//                     setProductData(processedData);
//                     setFilteredProductData(filterProductData(processedData));
//                 } else {
//                     message.error('Có lỗi xảy ra: ' + response.data.message);
//                 }
//             } catch (error) {
//                 message.error('Lỗi khi gọi API.');
//             }finally {
//               setLoading(false); // Kết thúc loading
//           }
//         };

//         const fetchMemberData = async () => {
//             try {
//                 const response = await axios.get('http://localhost:5000/members-sales-stat');
//                 setMemberData(response.data);
//                 setFilteredMemberData(filterMemberData(response.data));
//             } catch (error) {
//                 message.error('Lỗi khi gọi API thành viên.');
//             }
//         };

//         fetchProductData();
//         fetchMemberData();
//     }, [selectedDateRange, selectedLevels, selectedMemberDateRange, selectedMemberLevel]);

//     // Cột cho bảng thống kê sản phẩm
//     const productColumns = [
//         {
//             title: 'ID Sản phẩm',
//             dataIndex: 'productId',
//             key: 'productId',
//             sorter: (a, b) => a.productId.localeCompare(b.productId),
//             sortDirections: ['ascend', 'descend'],
//         },
//         {
//             title: 'Tên sản phẩm',
//             dataIndex: 'productName',
//             key: 'productName',
//             sorter: (a, b) => a.productName.localeCompare(b.productName),
//             sortDirections: ['ascend', 'descend'],
//             render: (text, record) => (
//                 <Tooltip title={record.productName}>
//                     <a href={record.productLink} target="_blank" rel="noopener noreferrer">
//                         {text}
//                     </a>
//                 </Tooltip>
//             ),
//         },
//         {
//             title: 'Ảnh thu nhỏ',
//             dataIndex: 'thumbnail',
//             key: 'thumbnail',
//             render: (text, record) => (
//                 <img src={record.thumbnail} alt={record.productName} style={{ width: 50, height: 50 }} />
//             ),
//         },
//         {
//             title: 'Số lượng bán ra',
//             dataIndex: 'quantitySold',
//             key: 'quantitySold',
//             sorter: (a, b) => a.quantitySold - b.quantitySold,
//             sortDirections: ['ascend', 'descend'],
//         },
//         {
//             title: 'Tổng doanh số bán ra',
//             dataIndex: 'totalSales',
//             key: 'totalSales',
//             sorter: (a, b) => a.totalSales - b.totalSales,
//             sortDirections: ['ascend', 'descend'],
//             render: value => `${value.toLocaleString()} đ`,
//         },
//         {
//             title: 'Level',
//             dataIndex: 'level',
//             key: 'level',            
//             render: level => `Level ${level}`,
//         },
//     ];

//     // Cột cho bảng thống kê thành viên
//     const memberColumns = [
//         {
//             title: 'ID Thành viên',
//             dataIndex: 'memberId',
//             key: 'memberId',
//             sorter: (a, b) => a.memberId.localeCompare(b.memberId),
//             sortDirections: ['ascend', 'descend'],
//         },
//         {
//             title: 'Tên thành viên',
//             dataIndex: 'memberName',
//             key: 'memberName',
//             sorter: (a, b) => a.memberName.localeCompare(b.memberName),
//             sortDirections: ['ascend', 'descend'],
//         },
//         {
//             title: 'Số lượng bán ra',
//             dataIndex: 'totalQuantitySold',
//             key: 'totalQuantitySold',
//             sorter: (a, b) => a.totalQuantitySold - b.totalQuantitySold,
//             sortDirections: ['ascend', 'descend'],
//         },
//         {
//             title: 'Tổng doanh số bán ra',
//             dataIndex: 'totalSales',
//             key: 'totalSales',
//             sorter: (a, b) => a.totalSales - b.totalSales,
//             sortDirections: ['ascend', 'descend'],
//             render: value => `${value.toLocaleString()} đ`,
//         },
//     ];

//     // Hàm lọc dữ liệu sản phẩm
//     const filterProductData = (dataToFilter) => {
//         return dataToFilter.filter(item => 
//             dayjs(item.saleDate).isBetween(selectedDateRange[0], selectedDateRange[1], 'day', '[]') &&
//             (selectedLevels.length === 0 || selectedLevels.includes(parseInt(item.level, 10)))
//         );
//     };

//     // Hàm lọc dữ liệu thành viên
//     const filterMemberData = (dataToFilter) => {
//         let filtered = dataToFilter.filter(item =>
//             dayjs(item.saleDate).isBetween(selectedMemberDateRange[0], selectedMemberDateRange[1], 'day', '[]')
//         );

//         if (selectedMemberLevel.length > 0) {
//             filtered = filtered.filter(item => selectedMemberLevel.includes(item.level));
//         }

//         return filtered.sort((a, b) => b.totalQuantitySold - a.totalQuantitySold).slice(0, 10);
//     };

//     const handleProductDateChange = (dates) => {
//         if (!dates) {
//             setSelectedDateRange([dayjs().startOf('month'), dayjs().endOf('month')]);
//         } else {
//             setSelectedDateRange(dates);
//         }
//         setFilteredProductData(filterProductData(productData));
//     };

//     const handleProductTableChange = (pagination, filters, sorter) => {
//         let currentData = filterProductData(productData);

//         if (filters.level && filters.level.length > 0) {
//             currentData = currentData.filter(item => filters.level.includes(item.level));
//         }

//         if (sorter.order) {
//             currentData = currentData.sort((a, b) => {
//                 if (sorter.order === 'ascend') {
//                     return a[sorter.field] > b[sorter.field] ? 1 : -1;
//                 } else {
//                     return a[sorter.field] < b[sorter.field] ? 1 : -1;
//                 }
//             });
//         }

//         setFilteredProductData(currentData);
//     };

//     const handleMemberDateChange = (dates) => {
//         if (!dates) {
//             setSelectedMemberDateRange([dayjs().startOf('month'), dayjs().endOf('month')]);
//         } else {
//             setSelectedMemberDateRange(dates);
//         }
//         setFilteredMemberData(filterMemberData(memberData));
//     };

//     const handleLevelChange = (checkedValues) => {
//         setSelectedLevels(checkedValues);
//         setFilteredProductData(filterProductData(productData));
//     };

//     const handleMemberLevelChange = (checkedValues) => {
//         setSelectedMemberLevel(checkedValues);
//         setFilteredMemberData(filterMemberData(memberData));
//     };

//     return (
//         <div>
//             <Tabs defaultActiveKey="1">
//                 <TabPane tab="Thống kê sản phẩm" key="1">
//                     <h2>Top sản phẩm bán chạy</h2>
//                     <Row gutter={[16, 16]} style={{ marginBottom: 16 }} align="middle" >
//                         <Col>
//                             <RangePicker
//                                 value={selectedDateRange}
//                                 onChange={handleProductDateChange}
//                                 format="DD/MM/YYYY"
//                             />
//                         </Col>
//                         <Col>
//                             <Checkbox.Group
//                                 options={[
//                                     { label: 'Level 0', value: 0 },
//                                     { label: 'Level 1', value: 1 },
//                                     { label: 'Level 2', value: 2 },
//                                 ]}
//                                 onChange={handleLevelChange}
//                                 value={selectedLevels}
//                             />
//                         </Col>
//                     </Row>
//                     <Skeleton loading={loading} active>
//                     <Table
//                         columns={productColumns}
//                         dataSource={filteredProductData}
//                         onChange={handleProductTableChange}
//                         pagination={{ pageSize: 10 }}
//                         rowKey={record => record.productId}
//                         bordered
//                         summary={pageData => {
//                             let totalQuantity = 0;
//                             let totalSales = 0;

//                             pageData.forEach(({ quantitySold, totalSales: sales }) => {
//                                 totalQuantity += quantitySold;
//                                 totalSales += sales;
//                             });

//                             return (
//                                 <Table.Summary.Row>
//                                     <Table.Summary.Cell colSpan={3}>Tổng cộng</Table.Summary.Cell>
//                                     <Table.Summary.Cell>{totalQuantity}</Table.Summary.Cell>
//                                     <Table.Summary.Cell>{totalSales.toLocaleString()} đ</Table.Summary.Cell>
//                                     <Table.Summary.Cell />
//                                 </Table.Summary.Row>
//                             );
//                         }}
//                     />
//                      </Skeleton>
//                 </TabPane>
//                 <TabPane tab="Top 10 thành viên" key="2">
//                     <h2>Top 10 thành viên có số lượng bán ra cao nhất</h2>
//                     <Row gutter={[16, 16]} style={{ marginBottom: 16 }}align="middle">
//                         <Col>
//                             <RangePicker
//                                 value={selectedMemberDateRange}
//                                 onChange={handleMemberDateChange}
//                                 format="DD/MM/YYYY"
//                             />
//                         </Col>
//                         <Col>
//                             <Checkbox.Group
//                                 options={[
//                                     { label: 'Level 1', value: 1 },
//                                     { label: 'Level 2', value: 2 },
//                                 ]}
//                                 onChange={handleMemberLevelChange}
//                                 value={selectedMemberLevel}
//                             />
//                         </Col>
//                     </Row>
//                     <Table
//                         columns={memberColumns}
//                         dataSource={filteredMemberData}
//                         pagination={false}
//                         rowKey={record => record.memberId}
//                         bordered
//                     />
//                 </TabPane>
//             </Tabs>
//         </div>
//     );
// }

// export default Statistics;
