import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Row, Col, Checkbox, message, Tooltip, Skeleton } from 'antd';
import dayjs from 'dayjs';
// import { getProductSales } from '../api';
import { getProductSales } from '../../api';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const { RangePicker } = DatePicker;

const StaticsProduct = () => {
    const [productData, setProductData] = useState([]);
    const [filteredProductData, setFilteredProductData] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProductData = async () => {
            setLoading(true);
            try {
                const user_id = localStorage.getItem('user_id');
                const start_date = selectedDateRange[0].format('YYYY-MM-DD');
                const end_date = selectedDateRange[1].format('YYYY-MM-DD');

                const response = await getProductSales({
                    user_id: user_id,
                    start_date: start_date,
                    end_date: end_date,
                });

                if (response.data.success) {
                    const processedData = response.data.data.map((item) => {
                        const truncatedProductName = item.productName.length > 100 
                            ? item.productName.substring(0, 100) + '...' 
                            : item.productName;

                        const thumbnailUrl = item.thumbnail || 'https://placehold.co/100x100/png';

                        return {
                            ...item,
                            productName: truncatedProductName,
                            productFullname:item.productName,
                            thumbnail: thumbnailUrl,
                        };
                    });

                    setProductData(processedData);
                    setFilteredProductData(filterProductData(processedData));
                } else {
                    message.error('Có lỗi xảy ra: ' + response.data.message);
                }
            } catch (error) {
                message.error('Lỗi khi gọi API.');
            } finally {
                setLoading(false); // Kết thúc loading
            }
        };

        fetchProductData();
    }, [selectedDateRange, selectedLevels]);

    const productColumns = [
        {
            title: 'ID Sản phẩm',
            dataIndex: 'productId',
            key: 'productId',
            sorter: (a, b) => a.productId.localeCompare(b.productId),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'productName',
            key: 'productName',
            sorter: (a, b) => a.productName.localeCompare(b.productName),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => (
                <Tooltip title={record.productFullname}>
                    <a href={record.productLink} target="_blank" rel="noopener noreferrer">
                        {text}
                    </a>
                </Tooltip>
            ),
        },
        {
            title: 'Ảnh thu nhỏ',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            render: (text, record) => (
                <img src={record.thumbnail} alt={record.productName} style={{ width: 50, height: 50 }} />
            ),
        },
        {
            title: 'Số lượng bán ra',
            dataIndex: 'quantitySold',
            key: 'quantitySold',
            sorter: (a, b) => a.quantitySold - b.quantitySold,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Tổng doanh số bán ra',
            dataIndex: 'totalSales',
            key: 'totalSales',
            sorter: (a, b) => a.totalSales - b.totalSales,
            sortDirections: ['ascend', 'descend'],
            render: value => `${value.toLocaleString()} đ`,
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',            
            render: level => `Level ${level}`,
        },
    ];

    const filterProductData = (dataToFilter) => {
        return dataToFilter.filter(item => 
            dayjs(item.saleDate).isBetween(selectedDateRange[0], selectedDateRange[1], 'day', '[]') &&
            (selectedLevels.length === 0 || selectedLevels.includes(parseInt(item.level, 10)))
        );
    };

    const handleProductDateChange = (dates) => {
        if (!dates) {
            setSelectedDateRange([dayjs().startOf('month'), dayjs().endOf('month')]);
        } else {
            setSelectedDateRange(dates);
        }
        setFilteredProductData(filterProductData(productData));
    };

    const handleProductTableChange = (pagination, filters, sorter) => {
        let currentData = filterProductData(productData);

        if (filters.level && filters.level.length > 0) {
            currentData = currentData.filter(item => filters.level.includes(item.level));
        }

        if (sorter.order) {
            currentData = currentData.sort((a, b) => {
                if (sorter.order === 'ascend') {
                    return a[sorter.field] > b[sorter.field] ? 1 : -1;
                } else {
                    return a[sorter.field] < b[sorter.field] ? 1 : -1;
                }
            });
        }

        setFilteredProductData(currentData);
    };

    const handleLevelChange = (checkedValues) => {
        setSelectedLevels(checkedValues);
        setFilteredProductData(filterProductData(productData));
    };

    return (
        <>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }} align="middle">
                <Col xs={24} sm={8} md={6}>
                    <RangePicker
                        value={selectedDateRange}
                        onChange={handleProductDateChange}
                        format="DD/MM/YYYY"
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Checkbox.Group
                        options={[
                            { label: 'Level 0', value: 0 },
                            { label: 'Level 1', value: 1 },
                            { label: 'Level 2', value: 2 },
                        ]}
                        onChange={handleLevelChange}
                        value={selectedLevels}
                    />
                </Col>
            </Row>
            <Skeleton loading={loading} active>
                <Table
                    columns={productColumns}
                    dataSource={filteredProductData}
                    onChange={handleProductTableChange}
                    pagination={{ pageSize: 10 }}
                    rowKey={record => record.productId}
                    bordered
                    summary={pageData => {
                        let totalQuantity = 0;
                        let totalSales = 0;

                        pageData.forEach(({ quantitySold, totalSales: sales }) => {
                            totalQuantity += quantitySold;
                            totalSales += sales;
                        });

                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={3}>Tổng cộng</Table.Summary.Cell>
                                <Table.Summary.Cell>{totalQuantity}</Table.Summary.Cell>
                                <Table.Summary.Cell>{totalSales.toLocaleString()} đ</Table.Summary.Cell>
                                <Table.Summary.Cell />
                            </Table.Summary.Row>
                        );
                    }}
                />
            </Skeleton>
        </>
    );
};

export default StaticsProduct;
