import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, DatePicker, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { register } from '../api';
import { useNavigate } from 'react-router-dom';
import 'dayjs/locale/vi';
dayjs.locale('vi');
const { Title } = Typography;

const Register = () => {
    const [form] = Form.useForm();
    const [referrer_id, setReferrer_id] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Đăng Ký Tài Khoản"; // Cập nhật tiêu đề của trang

        const hash = window.location.hash;
        const urlParams = new URLSearchParams(hash.split('?')[1]);
        const ref = urlParams.get('ref');
        if (ref) {
            setReferrer_id(ref);
            form.setFieldsValue({ referrer_id: ref });
        }
    }, [form]);

    const onFinish = async (values) => {
        // Định dạng lại ngày tháng năm sinh trước khi gửi đi
        const formattedValues = {
            ...values,
            dob: values.dob ? dayjs(values.dob).format('YYYY-MM-DD') : '',
        };

        try {
            const response = await register(formattedValues);
            if(response.data.success){
                message.success('Đăng ký thành công!');  
                navigate('/login', { replace: true });              
            }
            else{
                message.error(`Đăng ký thất bại: ${response.data.data}`);
            }            
        } catch (error) {
            message.error(`Có lỗi xảy ra: ${error.message}`);
        }
    };

    return (
        <Row justify="center" style={{ marginTop: '50px' }}>
            <Col xs={24} sm={20} md={16} lg={12} xl={8}>
                <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Đăng Ký Tài Khoản
                </Title>
                <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    layout="vertical"
                    size="large"
                >
                    <Form.Item label="Người giới thiệu" name="referrer_id">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Họ và tên"
                        name="display_name"
                        rules={[{ required: true, message: 'Vui lòng nhập họ và tên!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Số điện thoại"
                        name="user_phone"
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="user_email"
                        rules={[{ required: true, type: 'email', message: 'Vui lòng nhập email hợp lệ!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mật khẩu"
                        name="password"
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Nhập lại mật khẩu"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Vui lòng nhập lại mật khẩu!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Mật khẩu không khớp!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Ngày tháng năm sinh"
                        name="dob"
                        rules={[{ required: true, message: 'Vui lòng chọn ngày tháng năm sinh!' }]}
                    >
                        <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="Số CMND hoặc CCCD"
                        name="id_number"
                        rules={[{ required: true, message: 'Vui lòng nhập số CMND hoặc CCCD!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Số tài khoản ngân hàng"
                        name="bank_account_number"
                        rules={[{ required: true, message: 'Vui lòng nhập số tài khoản ngân hàng!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Tên chủ tài khoản"
                        name="bank_account_name"
                        rules={[{ required: true, message: 'Vui lòng nhập tên chủ tài khoản!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Tên ngân hàng"
                        name="bank_name"
                        rules={[{ required: true, message: 'Vui lòng nhập tên ngân hàng!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Chi nhánh ngân hàng"
                        name="bank_branch"
                        rules={[{ required: true, message: 'Vui lòng nhập chi nhánh ngân hàng!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Đăng ký
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default Register;
